var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c(
      "div",
      {
        staticClass: "loader-wrapper",
        class: {
          "no-bg": !_vm.background,
          "small-loader": _vm.size === "small",
          "full-bg": _vm.size === "full",
        },
      },
      [
        _c("div", { staticClass: "loader-body" }, [
          _c(
            "p",
            { staticStyle: { "text-align": "center" } },
            [_c("a-spin")],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }